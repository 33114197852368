<template>
  <div
    :style="{
      fontSize: `${size}px`,
      color: color,
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: '1',
    }"
  >
    <!-- 根据 type 动态渲染不同的 Emoji，并且添加 title 显示提示文字 -->
    <span v-if="type === 'hard'" title="富有成就感的一天">🔥</span>
    <span v-else-if="type === 'lowmood'" title="完成低情绪任务">😞</span>
    <span v-else-if="type === 'novelty'" title="充满灵感的一天">✨</span>
    <span v-else-if="type === 'mutiplegoal'" title="丰富的一天">🚩</span>
    <span v-else-if="type === 'hardrelated'" title="尝试困难任务的一天">💪</span>
    <span v-else-if="type === 'lowmoodrelated'" title="和情绪作斗争的一天">💢</span>
    <span v-else >🟢</span> <!-- 默认图标 -->
    
  </div>
</template>

<script>
export default {
  name: "DynamicIcon",
  props: {
    type: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 13,
    },
  },
};
</script>
